<template>
  <div>
    <v-dialog
      v-model="showLocal"
      max-width="550px"
    >
      <v-card>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" >
              <span class="headline">{{this.$store.state.companyName}} </span>
            </v-col>
          </v-row>
            <v-row dense>
            <v-col cols="12" >
              <span class="headline"> Odabir parametara za Izvještaj</span>
            </v-col>
          </v-row>

          <v-divider class="mt-3 mb-3"></v-divider>
          <v-row dense>
              <v-col xs="4" >
                <input type="date" ref="docdate" v-model="startDate" />
              </v-col>
              <v-col xs="4" >
                <input type="date" v-model="endDate" />
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="3" xl="3"  >
                <v-select
                v-model="selectedMonth"
                :items="months"
                label="Mjesec"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" >
              <v-checkbox
                v-model="hideDetails"
                label="Samo ukupno prikaži"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-2">
                <v-autocomplete
                  :items="allEmp"
                  v-model="selectEmp"
                  label="Odaberi radnika"
                  item-text="employeeFullName"
                  item-value="id"
                  return-object
                  persistent-hint
                  clearable
                ></v-autocomplete>
              </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" >
              <v-btn color="primary" block @click="doReport(1)">Neoporezive isplate pdf</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" >
              <v-btn color="primary" block @click="doReport(2)">Neoporezive isplate excel</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" >
              <v-btn color="primary" block @click="doAna(1)">Plaće analiza pdf</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" >
              <v-btn color="primary" block @click="doAna(2)">Potvrda o plaći pdf</v-btn>
            </v-col>
          </v-row>


        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ms-auto"
            text
            @click="doClose()"
          >Odustani</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <SalariePrintCompensation ref="prnt" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <SalariePrintCompensation ref="prnt" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <SalariePrintReport ref="prnt2" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <SalariePrintReportPotvrdaOPlaci ref="prnt3" />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import dayjs from 'dayjs'
import {freeCompensation} from './freeCompensation'
import {freeReport} from './freeReport'
import SalariePrintCompensation from './SalariePrintCompensation'
import SalariePrintReport from './SalariePrintReport'
import SalariePrintReportPotvrdaOPlaci from './SalariePrintReportPotvrdaOPlaci'
import EmployeeService from '@/services/EmployeeService'
import xlsx from 'json-as-xlsx'
import {dynamicSortMultiple} from '@/snippets/allSnippets'

export default {
  name: 'selectcompensation',
  props: [],
  data: ()=> ({
    startDate: null,
    endDate: null,
    months: [1,2,3,4,5,6,7,8,9,10,11,12],
    selectedMonth: null,
    showLocal: true,
    card: {},
    hideDetails: false,
    mainQuery: {},
    allEmp: [],
    selectEmp: null,
    totalAll: 0,
    order: 0
  }),
  async mounted () {
    this.$refs.docdate.focus()
    this.startDate = dayjs().startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs().endOf('year').format('YYYY-MM-DD')
    this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    await EmployeeService.index(this.mainQuery)
    .then(res => {
      if (res.data && res.data.employees) {
        this.allEmp = res.data.employees
      }
    })
    .catch (err => {
      // eslint-disable-next-line
      console.log(err)
    })
  },
  methods: {
    doClose() {
      const newTikTok = this.$store.state.tikTok + 1
      this.$store.dispatch('setTikTok', newTikTok)
    },
    async doReport(typeReport) {
        freeCompensation(this.$store.state.companyid, this.startDate, this.endDate, 1, this.selectEmp)
        .then(res => {
          const newObj = {}
          this.card = {...newObj}
          this.card.startDate = this.startDate
          this.card.endDate = this.endDate
          this.card.type = 1
          this.card.user = null
          this.card.head1 = null
          this.card.head2 = this.hideDetails
          this.card.head3 = null
          this.card.head4 = null
          this.card.head5 = null
          this.card.items = res ? res : []
          this.$store.dispatch('setPrintObj', this.card)
          if (typeReport === 1) {
            this.$refs.prnt.print()
          }
          if (typeReport === 2) {
            this.doXlsx()
          }
          const newTikTok = this.$store.state.tikTok + 1
          this.$store.dispatch('setTikTok', newTikTok)
        })
        .catch(err => {
          console.log('err', err)
        })
  
        this.showLocal = false
        
      //const haveResult = await freeCompensation(this.$store.state.companyid, this.startDate, this.endDate, 1, null)
      
    },
    async doAna(typeReport) {
      freeReport(this.$store.state.companyid, this.startDate, this.endDate, 1, this.selectEmp)
        .then(res => {
          const newObj = {}
          this.card = {...newObj}
          this.card.startDate = this.startDate
          this.card.endDate = this.endDate
          this.card.type = 1
          this.card.user = null
          this.card.head1 = null
          this.card.head2 = this.hideDetails
          this.card.head3 = null
          this.card.head4 = null
          this.card.head5 = null
          this.card.items = res ? res : []
          this.$store.dispatch('setPrintObj', this.card)
          if (typeReport === 1) {
            this.$refs.prnt2.print()
          }
          if (typeReport === 2) {
            this.$refs.prnt3.print()
          }
          const newTikTok = this.$store.state.tikTok + 1
          this.$store.dispatch('setTikTok', newTikTok)
        })
        .catch(err => {
          console.log('err', err)
        })
  
        this.showLocal = false
        
      //const haveResult = await freeCompensation(this.$store.state.companyid, this.startDate, this.endDate, 1, null)
      
    },
    doXlsx() {
      this.comp = this.$store.state.companyActiv
      const persons = [...new Set(this.card.items.map(item => item.VatID))];

      const lines = []
      const f1 ={}
      f1.Opis = this.comp.CompanyNameShort
      lines.push(f1)
      const f2 ={}
      f2.Opis = this.comp.CompanyStreet + ' ' + this.comp.CompanyBuildingNameNumber
      lines.push(f2)
      const f3 ={}
      f3.Opis = this.comp.CompanyPostcode + ' ' + this.comp.CompanyCity
      lines.push(f3)
      const f4 ={}
      f4.Opis = 'OIB:' + this.comp.CompanyVATID  + (this.comp.Companyemail ? ', email:' +  this.comp.Companyemail: ' ') + ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a')
      lines.push(f4)

      const f5 ={}
      f5.Opis = 'Plaća - neoporezive isplate' 
      lines.push(f5)

      const f6 ={}
      f6.Opis = 'za period joppd od' + + (this.startDate ? dayjs(this.startDate).format('DD.MM.YYYY') + '/' : '') + (this.endDate ? dayjs(this.endDate).format('DD.MM.YYYY') : '')
      lines.push(f6)

      this.order = 0
      if (persons && persons.length > 0){
        persons.map(person => {
            const oneperson = this.card.items.filter(per => per.VatID === person)
            oneperson.sort(dynamicSortMultiple("value", 'JoppdDate'))
            this.order = 0
            let total = 0
            let totalBy = 0
            let cond = null

            if (oneperson && oneperson.length > 0){
              const f1 ={}
              f1.Radnik = '---------------------'
              lines.push(f1)
              const l ={}
              l.Opis = ''
              l.Radnik = (oneperson[0].First ? oneperson[0].First + ' ' : '') + (oneperson[0].Last ? oneperson[0].Last  : '')
              lines.push(l)
              
              oneperson.map(item=> {
                this.order += 1
                if (!cond) {
                  cond = item.value
                }
                if (cond !== item.value){
                  const haveIt = this.$store.state.joppdNotaxAll.find(tadd => (tadd.Value === cond) )
                  const avaY = haveIt && haveIt.OnYear && haveIt.OnYear > 0 ? haveIt.OnYear : null
                  const restY = avaY && haveIt.OnYear ? haveIt.OnYear - totalBy : null
                  const l ={}
                  l.Opis = ''
                  l.Order = ''
                  l.Radnik = 'Uk.'+(cond && haveIt && haveIt.Description ? haveIt.Description.substring(0, 90) : '')
                  l.vrsta = cond
                  l.BrojJoppd = item.BrojJoppd
                  l.JoppdPoslan = dayjs(item.JoppdPoslanDate ).format('DD.MM.YYYY') 
                  l.Iznos = totalBy
                  l.GodisnjeDozvoljeno = avaY
                  l.ZaIskoristiti = restY
                  lines.push(l)
                  totalBy = 0
                  cond = item.value
                }
                total += item.amount
                totalBy += item.amount
                this.totalAll += item.amount
                const l ={}
                l.Opis = ''
                l.Order = this.order
                l.Radnik = ''
                l.vrsta = item.value
                l.BrojJoppd = item.BrojJoppd
                l.JoppdPoslan = dayjs(item.JoppdPoslanDate ).format('DD.MM.YYYY') 
                l.Iznos = (item.amount)
                l.GodisnjeDozvoljeno = null
                l.ZaIskoristiti = null
                lines.push(l)
              })
            }
            // do final for person
            if (cond){
              const haveIt = this.$store.state.joppdNotaxAll.find(tadd => (tadd.Value === cond) )
              const avaY = haveIt && haveIt.OnYear && haveIt.OnYear > 0 ? haveIt.OnYear : null
              const restY = avaY && haveIt.OnYear ? haveIt.OnYear - totalBy : null
              const l ={}
              l.Opis = ''
              l.Order = ''
              l.Radnik = 'Uk.'+(cond && haveIt && haveIt.Description ? haveIt.Description.substring(0, 90) : '')
              l.vrsta = cond
              l.BrojJoppd = ''
              l.JoppdPoslan = ''
              l.Iznos = totalBy
              l.GodisnjeDozvoljeno = avaY
              l.ZaIskoristiti = restY
              lines.push(l)
            }
            const l ={}
            l.Opis = ''
            l.Order = ''
            l.Radnik = 'UKUPNO : ' + (oneperson[0].First ? oneperson[0].First + ' ' : '') + (oneperson[0].Last ? oneperson[0].Last  : '')
            l.Iznos = (total)
            lines.push(l)
            
        })
      }

      const f10 ={}
      f10.Radnik = '======================='
      lines.push(f10)
      const tt ={}
      tt.Opis = ''
      tt.Order = ''
      tt.Radnik = 'SVEUKUPNO : '
      tt.Iznos = (this.totalAll)
      lines.push(tt)
      console.log('lines', lines)

      const companyName = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyNameDatabase ? this.$store.state.companyActiv.CompanyNameDatabase : ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
      const sheetNm = 'Neo_Isp_' + this.$store.state.businessYear + '_' + currDate
      let data = [
      {
        sheet: sheetNm,
        columns: [
          {label: 'Opis', value: 'Opis'},
          {label: 'Rbr', value: 'Order'},
          {label: 'Radnik', value: 'Radnik'},
          {label: 'Vrsta',value: 'vrsta' },
          {label: 'BrojJoppd' , value: 'BrojJoppd' },
          {label: 'Joppd Poslan', value: 'JoppdPoslan' },
          {label: 'Iznos', value: 'Iznos', format: "€#,##0.00"},
          {label: 'Godisnje Dozvoljeno' , value: 'GodisnjeDozvoljeno', format: "€#,##0.00" },
          {label: 'Za Iskoristiti' , value: 'ZaIskoristiti', format: "€#,##0.00" },

        ],
        content: lines
      },
    ]

      const settings = {
        fileName: companyName + '_NeoporeziveIsplate_' + currDate, // Name of the spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }
      xlsx( data, settings)

    }
  },
  computed: {
  },
  watch: {

  },
  components: {
    SalariePrintCompensation,
    SalariePrintReport,
    SalariePrintReportPotvrdaOPlaci
  }

}

</script>