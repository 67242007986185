<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
import {dynamicSortMultiple} from '@/snippets/allSnippets'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprintreportpotvrdaoplaci',
  props: [],
  data () {
    return {
      msg: '',
      documentName: '',
      documentName2: '',
      docDefinition: null,
      content: [],
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      totalIz: 0,
      totalNa: 0,
      Bruto1: 0,
      Bruto3: 0,
      Neto3: 0,
      FinBruto1: 0,
      dodIz: 0,
      FinBruto2: 0,
      dopIz: 0,
      FinNeto1ls: 0,
      FinFreeTotalToUse: 0,
      FinTax1Am: 0,
      FinTax12Am: 0,
      FinNeto2: 0,
      neoT: 0,
      obuT: 0,
      FinNeto3: 0,
      FinBruto3: 0,
      notFinal: true
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = {...langEn}
      this.langC = {...commEn}
    }
    if (this.$store.state.language === 'HR') {
      this.lang = {...langHr}
      this.langC = {...commHr}
    }

  },
  methods: {
    async print () {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      this.printDoc()
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      const persons = [...new Set(this.ana.items.map(item => item.VatID))];

      this.initDocument()
      this.addHead()

      if (persons && persons.length > 0){
        persons.map(person => {
          const oneperson = this.ana.items.filter(per => per.VatID === person)
          oneperson.sort(dynamicSortMultiple("Year", 'Month'))
          this.addEmptyRow()
          this.addPerson(oneperson[0])
          this.addItemsHeader(this.notFinal)
          this.addLine()
          this.addItems(oneperson)
        })
      }

      // this.initDocument()
      // this.addHead()
      // this.addLine()
      // this.addItemsHeader()
      // this.addLine()
      // this.addItems()
      // this.addEmptyRow()
      // this.addEmptyRow()
      this.addLine()
      // this.notFinal = false
      // this.addItemsHeader(this.notFinal)
      // this.addItemsTotals()
      // this.addLine()

  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 30,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead() {
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.Companyemail ? 'email:' +  this.comp.Companyemail + ' \n' : ' ') , fontSize: 8 },
                { text: 'OIB:' + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: 'Potvrda o plaći' + '\n', fontSize: 18, bold: true },
                { text: 'za ' + (this.ana.startDate ? dayjs(this.ana.startDate).format('DD.MM.YYYY') + '/' : '') + (this.ana.endDate ? dayjs(this.ana.endDate).format('DD.MM.YYYY')  : '') , fontSize: 12, bold: true },
                { text: (this.ana.head3 ? this.ana.head3 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head4 ? this.ana.head4 + ' \n' : ''), fontSize: 8, bold: true },
                { text: (this.ana.head5 ? this.ana.head5 + ' \n' : ''), fontSize: 8, bold: true },
              ]
            },
            {
              // % width
              width: '20%',
              text: [                 
                { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },

    addPerson (person) {
      const docPart = {
            columns: [
              {
                width: '40%',
                text: [
                  { text: (person.FullName ? person.FullName + ' , ' : '')  + (person.VatID ? person.VatID + ' , ' : '') + (person.eemail ? person.eemail  : '') , fontSize: 10 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addItemsHeader (notFinal) {
      const docPart = {
          columns: [
            {
              width: '2%',
              text: (notFinal ? 'Rbr': '') + '\n', fontSize: 8 
            },
            {
              width: '15%',
              text: (notFinal ? 'Vrsta' : '') + '\n', fontSize: 8
            },
            {
              width: '10%',
              text: [
                { text: (notFinal ? 'Broj obr.' : '') + '\n', fontSize: 8, alignment: 'left', },
                { text: (notFinal ? '(mj/god - br)' : '') , fontSize: 6, alignment: 'left' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Plaća brutto' , fontSize: 7, alignment: 'center' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Isplaćena' + ' \n' + 'netto plaća', fontSize: 7, alignment: 'center' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Broj sati' + ' \n' + 'puno rad.vr.', fontSize: 7, alignment: 'center' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Broj sati' + ' \n' + 'prekovremeni' , fontSize: 7, alignment: 'center' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Broj sati' + ' \n' + 'odsut.s rada' , fontSize: 7, alignment: 'center' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Ukupno' + ' \n' + 'sati' , fontSize: 7, alignment: 'center' },
              ]
            },
            {
              width: '8%',
              text: [
                { text: 'Priv.nesp.' + ' \n' + 'na teret HZZO', fontSize: 7, alignment: 'center', bold: true },
              ]
            },
              {
              width: '8%',
              text: [
                { text: 'Sati prema' + ' \n' + 'kalendaru' , fontSize: 7, alignment: 'center', },
              ]
            }
          ]
        }
      this.content.push(docPart)
    },
    addItems(person) {
          this.order = 0
            this.Bruto1 = 0
            this.Bruto3 = 0
            this.Neto3 = 0
            this.FinBruto1 = 0
            this.dodIz = 0
            this.FinBruto2 = 0
            this.dopIz = 0
            this.FinNeto1ls = 0
            this.FinFreeTotalToUse = 0
            this.FinTax1Am = 0
            this.FinTax12Am = 0
            this.FinNeto2 = 0
            this.neoT = 0
            this.obuT = 0
            this.FinNeto3 = 0
            this.FinBruto3 = 0
            let FinBruto1 = 0
            let dodIzT = 0
            let FinBruto2 = 0
            let dopIzT = 0
            let FinNeto1ls = 0
            let FinFreeTotalToUse = 0
            let FinTax1Am = 0
            let FinTax12Am = 0
            let FinNeto2 = 0
            let neoTT = 0
            let obuTT = 0
            let FinNeto3 = 0
            let FinBruto3 = 0
            let satiPunoRv = 0
            let satiPrekovremeni = 0
            let satiOdsutni = 0
            let satiUkupno = 0
            let satiNaHZZO = 0
            let satiKalendar = 0
            if (person) {
              person.map(item => {
                this.order += 1
                // const haveBrutoAddition1 = parseFloat(item.FinKoef1) 
                // const haveBrutoAddition2 = parseFloat(item.FinKoef2)
                // const haveBrutoAddition = haveBrutoAddition1 + haveBrutoAddition2
                this.Bruto1 += this.round(parseFloat(item.FinBruto2), 2)
                const dodIz1 = item.FinKoef1 && item.FinKoef1 !== 0 ? this.round(item.FinKoef1) : 0
                const dodIz2 = item.FinKoef2 && item.FinKoef2 !== 0 ? this.round(item.FinKoef2) : 0
                const dodIz = dodIz1 + dodIz2
                const dopIz1 = item.FinFrom1_Amount && item.FinFrom1_Amount !== 0 ? this.round(item.FinFrom1_Amount,2) : 0
                const dopIz2 = item.FinFrom2_Amount && item.FinFrom2_Amount !== 0 ? this.round(item.FinFrom2_Amount, 2) : 0
                const dopIz3 = item.FinFrom3_Amount && item.FinFrom3_Amount !== 0 ? this.round(item.FinFrom3_Amount,2) : 0
                const dopIz4 = item.FinFrom4_Amount && item.FinFrom4_Amount !== 0 ? this.round(item.FinFrom4_Amount,2) : 0
                const dopIz = dopIz1 + dopIz2 + dopIz3 +dopIz4
                const neo1 = item.FinAdd1_Amount && item.FinAdd1_Amount !== 0 ? this.round(item.FinAdd1_Amount,2) : 0
                const neo2 = item.FinAdd2_Amount && item.FinAdd2_Amount !== 0 ? this.round(item.FinAdd2_Amount,2) : 0
                const neo3 = item.FinAdd3_Amount && item.FinAdd3_Amount !== 0 ? this.round(item.FinAdd3_Amount,2) : 0
                const neo4 = item.FinAdd4_Amount && item.FinAdd4_Amount !== 0 ? this.round(item.FinAdd4_Amount,2) : 0
                const neoT = neo1 + neo2 + neo3 + neo4
                const obu1 = item.Fin1LoanBankAmount !== 0 ? this.round(item.Fin1LoanBankAmount,2) : 0
                const obu2 = item.Fin2LoanBankAmount !== 0 ? this.round(item.Fin2LoanBankAmount,2) : 0
                const obu3 = item.Fin3LoanBankAmount !== 0 ? this.round(item.Fin3LoanBankAmount,2) : 0
                const obuT = obu1 + obu2 + obu3
                this.Bruto3 += this.round(parseFloat(item.FinBruto3),2)
                this.Neto3 += this.round(parseFloat(item.FinNeto3),2)

                this.FinBruto1 += this.round(item.FinBruto1 ,2)
                this.dodIz += this.round(dodIz ,2)
                this.FinBruto2 += this.round(item.FinBruto2 ,2)
                this.dopIz += this.round(dopIz ,2)
                this.FinNeto1ls += this.round(item.FinNeto1ls ,2)
                this.FinFreeTotalToUse += this.round(item.FinFreeTotalToUse ,2)
                this.FinTax1Am += this.round(item.FinTax1Am ,2)
                this.FinTax12Am += this.round(item.FinTax12Am ,2)
                this.FinNeto2 += this.round(item.FinNeto2 ,2)
                this.neoT += this.round(neoT ,2)
                this.obuT += this.round(obuT ,2)
                this.FinNeto3 += this.round(item.FinNeto3 ,2)
                this.FinBruto3 += this.round(item.FinBruto3 ,2)

                FinBruto1 += this.round(item.FinBruto1 ,2)
                dodIzT += this.round(dodIz ,2)
                FinBruto2 += this.round(item.FinBruto2 ,2)
                dopIzT += this.round(dopIz ,2)
                FinNeto1ls += this.round(item.FinNeto1ls ,2)
                FinFreeTotalToUse += this.round(item.FinFreeTotalToUse ,2)
                FinTax1Am += this.round(item.FinTax1Am ,2)
                FinTax12Am += this.round(item.FinTax12Am ,2)
                FinNeto2 += this.round(item.FinNeto2 ,2)
                neoTT += this.round(neoT ,2)
                obuTT += this.round(obuT ,2)
                FinNeto3 += this.round(item.FinNeto3 ,2)
                FinBruto3 += this.round(item.FinBruto3 ,2)

                satiPunoRv += this.round(item.satiPunoRv ,2)
                satiPrekovremeni += this.round(item.satiPrekovremeni ,2)
                satiOdsutni += this.round(item.satiOdsutni ,2)
                satiUkupno += this.round(item.satiUkupno ,2)
                satiNaHZZO += this.round(item.satiNaHZZO ,2)
                satiKalendar += this.round(item.satiKalendar ,2)

                const docPart = {
                columns: [
                    {
                      width: '2%',
                      fontSize: 7,
                      alignment: 'left',
                      text: this.order + '.'
                    },
                    {
                      width: '15%',
                      fontSize: 7,
                      alignment: 'left',
                      text:(item.VrstaObracuna ? item.VrstaObracuna + ' ' : '') + ' \n',
                    },
                    {
                      width: '10%',
                      fontSize: 7,
                      alignment: 'left',
                      text: (item.Month ? item.Month + '/' : '') + (item.Year ? item.Year + ' - ' : '') + (item.Order ? item.Order  : '') + ' \n',
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.FinBruto2 && item.FinBruto2 !== 0 ? this.formatPrice(item.FinBruto2) + '€ \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.FinNeto2 && item.FinNeto2 !== 0 ? this.formatPrice(item.FinNeto2) + '€ \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.satiPunoRv && item.satiPunoRv !== 0 ? this.formatPrice(item.satiPunoRv) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.satiPrekovremeni && item.satiPrekovremeni !== 0 ? this.formatPrice(item.satiPrekovremeni) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.satiOdsutni && item.satiOdsutni !== 0 ? this.formatPrice(item.satiOdsutni) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.satiUkupno  && item.satiUkupno !== 0 ? this.formatPrice(item.satiUkupno) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (item.satiNaHZZO && item.satiNaHZZO !== 0 ? this.formatPrice(item.satiNaHZZO) + ' \n' : '') , fontSize: 7, alignment: 'right', bold: true },
                      ]
                    },
                     {
                      width: '8%',
                      text: [
                        { text: (item.satiKalendar && item.satiKalendar !== 0 ? this.formatPrice(item.satiKalendar) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    }

                  ]
                }
              this.content.push(docPart)

            })

            this.addLineDash()
            const docPart2 = {
                columns: [
                    {
                      width: '2%',
                      fontSize: 7,
                      alignment: 'left',
                      text: ' \n',
                    },
                    {
                      width: '15%',
                      fontSize: 7,
                      alignment: 'left',
                      text: 'Ukupno: \n',
                    },
                    {
                      width: '10%',
                      fontSize: 7,
                      alignment: 'left',
                      text: ' \n',
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (this.FinNeto2 && this.FinNeto2 !== 0 ? this.formatPrice(this.FinNeto2) + '€ \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (this.FinNeto2 && this.FinNeto2 !== 0 ? this.formatPrice(this.FinNeto2) + '€ \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (satiPunoRv && satiPunoRv !== 0 ? this.formatPrice(satiPunoRv) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (satiPrekovremeni && satiPrekovremeni !== 0 ? this.formatPrice(satiPrekovremeni) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (satiOdsutni && satiOdsutni !== 0 ? this.formatPrice(satiOdsutni) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (satiUkupno  && satiUkupno !== 0 ? this.formatPrice(satiUkupno) + ' \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },
                    {
                      width: '8%',
                      text: [
                        { text: (satiNaHZZO && satiNaHZZO !== 0 ? this.formatPrice(satiNaHZZO) + ' \n' : '') , fontSize: 7, alignment: 'right', bold: true },
                      ]
                    },
                     {
                      width: '8%',
                      text: [
                        { text: (satiKalendar && satiKalendar !== 0 ? this.formatPrice(satiKalendar) + ' \n' : '') , fontSize: 7, alignment: 'right', },
                      ]
                    }


                  ]
                }
              this.content.push(docPart2)

              const docPart3 = {
                columns: [

                    {
                      width: '40%',
                      fontSize: 7,
                      alignment: 'left',
                      text: 'Osnovicu čini prosječni iznos plaće isplaćene u posljednjih šest mjeseci \n',
                    },
 
                    {
                      width: '20%',
                      text: [
                        { text: (this.FinBruto2 && this.FinBruto2 !== 0 ? 'Iznos po satu brutto:' + this.formatPrice(this.FinBruto2 / (satiUkupno > 0 ? satiUkupno : 1)) + '€ \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },

                    {
                      width: '20%',
                      text: [
                      { text: (this.FinNeto2 && this.FinNeto2 !== 0 ? 'Iznos po satu netto:' + this.formatPrice(this.FinNeto2 / (satiUkupno > 0 ? satiUkupno : 1)) + '€ \n' : '') , fontSize: 7, alignment: 'right' },
                      ]
                    },


                  ]
                }
              this.content.push(docPart3)
          }
    },



    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
